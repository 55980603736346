<template>
    <HeroPage v-if="pageData" :data="pageData" />
    <PageLayoutBuilder v-if="pageData" :data="pageData" />

    <section class="section video-grid-collection-section" v-if="adjustedData && showContent">
        <div class="container">
            <div class="column-wrapper">
                <VideoGridCollection :data="adjustedData" />
            </div>
        </div>
    </section>

    <section v-else-if="!adjustedData && showContent">
        <div class="container">
            <div class="column-wrapper">
                <div class="column content">
                    <p> Er zijn nog geen video's toegevoegd.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import HeroPage from "@/components/HeroPage";

import PageLayoutBuilder from "@/components/PageLayoutBuilder";

import VideoGridCollection from "@/components/VideoGridCollection";

import {
    fetchMultipleVideos,
    fetchPageBySlug
} from "@streampac.io/chef_sp_1";

export default {
    name: "Favorites",
    inject: ["isAuthenticated"],
    // metaInfo() {
    //     return {
    //         title:
    //             "Home | LEAD Hockey",
    //         description:
    //             "",
    //     };
    // },
    data() {
        return {
            pageData: null,
            adjustedData: null,
            showContent: false,
        };
    },
    components: {
        HeroPage,
        PageLayoutBuilder,
        VideoGridCollection
    },
    watch: {
        isAuthenticated() {
            this.pageData = null;
            setTimeout(() => {
                this.fetchPage();
            }, 300);
        },
        $route() {
            setTimeout(() => {
                this.fetchData();
                this.$store.commit("triggerFavoriteFetch", false);
            }, 150);
        },
        favoriteFetch(val) {
            if (val && val == true) {
                // this.$store.commit("triggerFavoriteFetch", true);

                setTimeout(() => {
                    this.fetchData();
                    this.$store.commit("triggerFavoriteFetch", false);
                }, 200);
            }
        },
        computedFavorites() {
            this.fetchData();
        }
    },
    computed: {
        favoriteFetch() {
            return this.$store.state.triggerFavoriteFetch
        },
        computedFavorites() {
            return this.$store.state.getUserFavoriteVideos
        },
    },
    created() {
        this.fetchPage();

        setTimeout(() => {
            this.fetchData();
        }, 1000);
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, 'my-favourites').then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
        fetchData() {
            const getUserFavoriteVideos = this.$store.state.getUserFavoriteVideos;

            const pluck = (array, key) => {
                return array.map((o) => o[key]);
            };

            if (getUserFavoriteVideos && getUserFavoriteVideos.length > 0) {
                const videoContentIds = pluck(
                    getUserFavoriteVideos,
                    "videoContentId"
                );

                fetchMultipleVideos(
                    this.isAuthenticated,
                    "?flattenFields=true",
                    videoContentIds
                ).then((response) => {
                    this.adjustedData = response;
                    this.showContent = true;
                });

            } else {
                setTimeout(() => {
                    this.showContent = true;
                }, 100);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.hero.page.no-bg {
    margin-bottom: 0;
}
</style>
